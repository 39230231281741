import React from 'react'
import styles from './bannerFooter.module.scss'
import { Link } from 'gatsby'

type bannerFooterType = {
   deskBannersFooter: {
      bannerUrl: string;
      altImg: string;
      slug: string;
      name: string;
   }[]
}

const bannerFooter: React.FC<bannerFooterType> = ({ deskBannersFooter }) => {
   return (
      <div className={styles.divPai}>
         <div className={styles.divsLaterais}>
            <Link to={deskBannersFooter[0].slug} className={styles.link}>
               <div className={styles.divsTopo}>
                  <img src={deskBannersFooter[0].bannerUrl} />
                  <p className={styles.subtitles}>{deskBannersFooter[0].name}</p>
               </div>
            </Link>
            <Link to={deskBannersFooter[1].slug} className={styles.link}>
               <div className={styles.divsInferiores}>
                  <img src={deskBannersFooter[1].bannerUrl} />
                  <p className={styles.subtitles}>{deskBannersFooter[1].name}</p>
               </div>
            </Link>
         </div>
         <Link to={deskBannersFooter[2].slug} className={styles.linkPrincipal}>
            <div className={styles.divPrincipal}>
               <img src={deskBannersFooter[2].bannerUrl} className={styles.tamanho100} />
               <p className={styles.subtitles}>{deskBannersFooter[2].name}</p>
            </div>
         </Link>
         <div className={styles.divsLaterais}>
            <Link to={deskBannersFooter[3].slug} className={styles.link}>
               <div className={styles.divsTopo}>
                  <img src={deskBannersFooter[3].bannerUrl} />
                  <p className={styles.subtitles}>{deskBannersFooter[3].name}</p>
               </div>
            </Link>
            <Link to={deskBannersFooter[4].slug} className={styles.link}>
               <div className={styles.divsInferiores}>
                  <img src={deskBannersFooter[4].bannerUrl} />
                  <p className={styles.subtitles}>{deskBannersFooter[4].name}</p>
               </div>
            </Link>
         </div>
      </div>
   )
}

export default bannerFooter
