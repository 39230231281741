import React from 'react'
import BannerCarousel from '../BannerCarousel/BannerCarousel'
import MiniBannersCarousel from './components/miniBannersCarousel/miniBannersCarousel'
import Showcase from '../Showcase/Showcase'
import BannerKits from './components/bannerKits/bannerKits'
import BannerFooter from './components/bannerFooter/bannerFooter'

type HomeType = {
    showcases: any
}

const deskItens = [
    {
        bannerUrl: 'https://magento-virtualjoias.com/media/banners/carousel/banner-momentos-desk.jpg?auto=webp',
        altImg: 'Banner da Coleção Momentos',
        slug: '/'
    },
    {
        bannerUrl: 'https://magento-virtualjoias.com/media/banners/carousel/banner-kitden-desk.jpg?auto=webp',
        altImg: 'Banner Kits de Namoro',
        slug: '/kits-de-namoro/'
    },
    {
        bannerUrl: 'https://magento-virtualjoias.com/media/banners/carousel/banner-escap-desk.jpg?auto=webp',
        altImg: 'Banner Escapulários',
        slug: '/escapularios/'
    },
    {
        bannerUrl: 'https://magento-virtualjoias.com/media/banners/carousel/banner-bqp-desk.jpg?auto=webp',
        altImg: 'Banner Coleção Bem Querer Prata',
        slug: '/bem-querer-prata/'
    }
]

const miniBannersDesk = [
    {
        miniBannerUrl: 'https://magento-virtualjoias.com/media/banners/miniBanners/minibanners_momentos_01.jpg?auto=webp',
        altImg: 'Banner Alianças de Namoro',
        slug: '/aliancas-de-namoro/'
    }, {
        miniBannerUrl: 'https://magento-virtualjoias.com/media/banners/miniBanners/minibanners_momentos_02.jpg?auto=webp',
        altImg: 'Banner Anéis de Noivado',
        slug: '/aneis-de-noivado/'
    }, {
        miniBannerUrl: 'https://magento-virtualjoias.com/media/banners/miniBanners/minibanners_momentos_03.jpg?auto=webp',
        altImg: 'Banner Alianças de Casamento',
        slug: '/aliancas-de-casamento/'
    }, {
        miniBannerUrl: 'https://magento-virtualjoias.com/media/banners/miniBanners/minibanners_momentos_04.jpg?auto=webp',
        altImg: 'Banner Mmommy',
        slug: '/mommy/'
    }, {
        miniBannerUrl: 'https://magento-virtualjoias.com/media/banners/miniBanners/minibanners_momentos_05.jpg?auto=webp',
        altImg: 'Banner Joias',
        slug: '/joias/'
    },
]

const deskBannersConjuntos = [
    {
        categoryName: 'Kits de Namoro',
        bannerUrl: 'https://magento-virtualjoias.com/media/banners/home/home-banner-kdnamoro.png?auto=webp&width=600&height=400',
        altImg: 'Banner Kit de Namoro',
        slug: '/kits-de-namoro/'
    }, {
        categoryName: 'Conjunto de Joias',
        bannerUrl: 'https://magento-virtualjoias.com/media/banners/home/home-banner-bq.png?auto=webp&width=600&height=400',
        altImg: 'Banner Conjunto de Joias',
        slug: '/conjuntos-de-joias/'
    }, {
        categoryName: 'Kits de Noivado',
        bannerUrl: 'https://magento-virtualjoias.com/media/banners/home/home-banner-kdnoivado.png?auto=webp&width=600&height=400',
        altImg: 'Banner Kit de Noivado',
        slug: '/kits-de-noivado/'
    }
]

const deskBannersFooter = [
    {
        bannerUrl: 'http://magento-virtualjoias.com/media/banners/home/banner-home-rodape01.png',
        altImg: 'Banner Alianças Ouro e Prata',
        slug: '/aliancas/',
        name: 'Alianças de Ouro e Prata'
    }, {
        bannerUrl: 'http://magento-virtualjoias.com/media/banners/home/banner-home-rodape02.png',
        altImg: 'Banner Anéis de Namoro',
        slug: '/aneis-de-namoro/',
        name: 'Anéis de Namoro'
    }, {
        bannerUrl: 'http://magento-virtualjoias.com/media/banners/home/banner-home-rodape03.png',
        altImg: 'Banner Bem Querer',
        slug: '/bem-querer-ouro/',
        name: 'Bem Querer'
    }, {
        bannerUrl: 'http://magento-virtualjoias.com/media/banners/home/banner-home-rodape04.png',
        altImg: 'Banner Anéis de Ouro',
        slug: '/aneis/',
        name: 'Anéis de Ouro'
    }, {
        bannerUrl: 'http://magento-virtualjoias.com/media/banners/home/banner-home-rodape05.png',
        altImg: 'Banner Linha Classic',
        slug: '/linha-classic/',
        name: 'Linha Classic'
    },
]

const Home: React.FC<HomeType> = ({ showcases }) => {

    const promocoesImperdiveis = showcases.edges.find((x: { node: { title: string } }) => x.node.title === "Promoções Imperdíveis").node;
    const linhaLoveways = showcases.edges.find((x: { node: { title: string } }) => x.node.title === "Linha Loveways").node;
    const aneisNoivado = showcases.edges.find((x: { node: { title: string } }) => x.node.title === "Anéis de Noivado").node;


    return (
        <div style={{ marginTop: '10px' }} >
            <BannerCarousel
                carouselItems={deskItens}
            />
            <MiniBannersCarousel
                miniBannersItens={miniBannersDesk}
            />
            <Showcase id={promocoesImperdiveis.id} title={promocoesImperdiveis.title} subtitle={promocoesImperdiveis.subtitle} products={promocoesImperdiveis.products} />
            <BannerKits deskBannersConjuntos={deskBannersConjuntos} />
            <Showcase id={linhaLoveways.id} title={linhaLoveways.title} subtitle={linhaLoveways.subtitle} products={linhaLoveways.products} />
            <Showcase id={aneisNoivado.id} title={aneisNoivado.title} subtitle={aneisNoivado.subtitle} products={aneisNoivado.products} />
            <BannerFooter deskBannersFooter={deskBannersFooter} />
        </div>
    )
}

export default Home
