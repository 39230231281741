import React from "react"
import styles from "./miniBannersCarousel.module.scss"
import { Link } from 'gatsby'

type MiniBannerCarouselType = {
  miniBannersItens: MiniBannerItem[]
}

type MiniBannerItem = {
  miniBannerUrl: string
  altImg: string
  slug: string
}

const miniBannersCarousel: React.FC<MiniBannerCarouselType> = ({
  miniBannersItens,
}) => {
  return (
    <div className={styles.principalDesk}>
      {miniBannersItens.map((image, index) => {
        return (
          <Link to={image.slug} key={index}>
            <img
              className={styles.imgMobile}
              alt={image.altImg}
              src={image.miniBannerUrl}
              key={index}
            />
          </Link>
        )
      })}
    </div>
  )
}

export default miniBannersCarousel
