import React, { useState, useEffect } from "react"
import { useSelector } from 'react-redux'
import Carousel from "react-slick"
import styles from "./Showcase.module.scss"
import { MICROSERVICE_WISHLIST } from "../../../userglobals"
import axios from 'axios'
import { addToWishlist, removeFromWishlist } from '../../store/actions'
import ProductCard from '../Category/components/productCard'
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded'
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';

type ShowcaseType = {
  id: string
  title: string
  subtitle: string
  products: ShowcaseProductType[]
}

type ShowcaseProductType = {
  id: number
  image: string
  name: string
  price: number
  rating: number | null
  sku: string
  specialPriceIndividual: string
  specialPriceRule: string
  slug: string
}

const Showcase: React.FC<ShowcaseType> = ({
  id,
  title,
  subtitle,
  products,
}) => {
  const wishlist = useSelector((state: any) => state.wishlist);
  const userAuth = useSelector((state: any) => state.userAuth);

  const [checked, setChecked] = useState(false);
  useEffect(() => {
    products.map(product => {
      const alreadyChecked = wishlist.some((x: any) => x.product_id === product.id.toString());
      if (alreadyChecked) {
        setChecked(true)
      }
    })
  }, [])

  const toggleCheckbox = (product: any) => {
    if (!checked) {
      setChecked(true)
      addToWishlist(product.id)
      if (userAuth.auth === true) {
        axios.post(MICROSERVICE_WISHLIST + "add-wishlist", { ...userAuth, productId: product.id })
        // .then(res=>console.log(res.data))
      }
    } else {
      setChecked(false)
      removeFromWishlist(product.id)
      if (userAuth.auth === true) {
        axios.post(MICROSERVICE_WISHLIST + "delete-wishlist", { ...userAuth, productId: product.id })
        // .then(res=>console.log(res.data))
      }
    }
  };

  function SamplePrevArrow(props: any) {
    const { style, onClick } = props;
    return (
      <div
        className={styles.arrowLeft}
        onClick={onClick}
      >
        <KeyboardArrowLeftRoundedIcon style={{width: '30px', height: '30px', margin: '5px'}} />
      </div>
    );
  }

  function SampleNextArrow(props: any) {
    const { style, onClick } = props;
    return (
      <div
        className={styles.arrowRight}
        onClick={onClick}
      >
        <KeyboardArrowRightRoundedIcon style={{width: '30px', height: '30px', margin: '5px'}} />
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <SamplePrevArrow />, 
    nextArrow: <SampleNextArrow />
  }


  //console.log(id, title, subtitle, products)
  return (
    <div>
      <div className={styles.divTitle}>
        <p className={styles.titleShowcase}>{title}</p>
        <p className={styles.subtitleShowcase}>{subtitle}</p>
      </div>
      <Carousel
        {...settings}
      >
        {products.map(product => {
          return (
            <div key={product.id} className={styles.productCard}>
              <ProductCard product={product} displayMode={'showcase'} />
            </div>
          )
        })}
      </Carousel>

    </div>
  )
}

export default Showcase
