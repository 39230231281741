import React from "react"
import Carousel from "react-slick"
import './BannerCarousel.scss'
import { Link } from 'gatsby'

type BannerCarousel = {
  carouselItems: BannerItem[]
}

type BannerItem = {
  bannerUrl: string
  altImg: string
  slug: string
}

const BannerCarousel: React.FC<BannerCarousel> = ({ carouselItems }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }

  return (
    <Carousel arrows={false} {...settings}>
      {carouselItems.map((item, index) => {
        return (
          <Link to={item.slug} key={index} className="bannerLink">
            <img src={item.bannerUrl} />
          </Link>
        )
      })}
    </Carousel>
  )
}

export default BannerCarousel
